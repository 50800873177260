import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/May-Day/index.vue'
import Index2 from '../views/May-Day/index2.vue'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/index2',
    name: 'index2',
    component: Index2
  },
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.isLogin)) {//判断是否需要登录
    if (localStorage['token']) {
      next();
    } else {
      next({
        path: "/login"
      });
    }
  } else {
    next()
  }
})

export default router



// export default new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes: [
//     {
//       path: '/',
//       name: 'index',
//       component: Index
//     },
//   ]
// })
